# Imprint/Impressum

## English

### Information According to § 5 TMG

Felicitas Pojtinger<br/>
Hirschkopfweg 8<br/>
72270 Baiersbronn

### Contact

Phone: <a href="tel:+49 152 33842326">+49 152 33842326</a><br/>
Email: <a href="mailto:felicitas@pojtinger.com">felicitas@pojtinger.com</a>

## Deutsch

### Angaben gemäß § 5 TMG

Felicitas Pojtinger<br/>
Hirschkopfweg 8<br/>
72270 Baiersbronn

### Kontakt

Telefon: <a href="tel:+49 152 33842326">+49 152 33842326</a><br/>
E-Mail: <a href="mailto:felicitas@pojtinger.com">felicitas@pojtinger.com</a>
